(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/full-height/assets/javascripts/full-height.js') >= 0) return;  svs.modules.push('/components/lb-utils/full-height/assets/javascripts/full-height.js');
'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
class FullHeight extends React.Component {
  constructor(props) {
    super(props);
    _defineProperty(this, "state", {
      height: 0
    });
    this.ref = React.createRef();
    this.calcHeight = this.calcHeight.bind(this);
  }
  componentDidMount() {
    this.calcHeight();
    window.addEventListener('orientationchange', this.calcHeight, false);
    window.addEventListener('resize', this.calcHeight, false);
  }
  componentDidUpdate(previousProps, previousState) {
    if (previousState.height !== this.state.height) this.calcHeight();
  }
  componentWillUnmount() {
    clearTimeout(this.debounce);
    window.removeEventListener('orientationchange', this.calcHeight);
    window.removeEventListener('resize', this.calcHeight);
  }
  calcHeight() {
    const setHeight = () => {
      const offset = this.ref.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      this.setState({
        height: windowHeight - offset
      });
      this.hasLoaded = true;
    };
    if (!this.hasLoaded) {
      setHeight();
      return;
    }
    if (this.debounce) {
      clearTimeout(this.debounce);
    }
    this.debounce = setTimeout(setHeight, 150);
  }
  render() {
    return React.createElement("div", {
      ref: this.ref,
      style: {
        height: this.state && this.state.height
      }
    }, this.props.children);
  }
}
setGlobal('svs.components.lbUtils.FullHeight', FullHeight);

 })(window);